<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md5 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				{{ getTitle() }}
			</v-flex>
			<v-flex md7 class="font-level-3-bold my-auto d-flex justify-content-end">
				<v-btn
					:disabled="line_items.length || grnDownloading ? false : true"
					:loading="grnDownloading"
					color="red lighten-1 white--text"
					tile
					deppressed
					@click="downloadAllGRN()"
				>
					<v-icon left>mdi-file-pdf</v-icon>
					pdf</v-btn
				>
			</v-flex>
		</v-layout>
		<Loading v-if="contentLoading"></Loading>
		<div v-else class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<!-- <th width="30" class="p-2 blue lighten-4 text-center"></th> -->
						<th width="50" class="p-2 blue lighten-4 text-center">#</th>
						<th class="p-2 blue lighten-4">GRN Number</th>
						<th class="p-2 blue lighten-4">Date</th>
						<th class="p-2 blue lighten-4">User</th>
						<th class="p-2 blue lighten-4">Status</th>
						<th class="p-2 blue lighten-4">Attachment</th>
						<th class="p-2 blue lighten-4 text-center"></th>
					</tr>
				</thead>
				<tbody v-if="line_items.length">
					<tr
						v-for="(row, index) in line_items"
						:key="index"
						@click="getDetail(row.uuid)"
						class="cursor-pointer"
					>
						<!-- <td class="p-2 border-top-light-grey text-center">
							<v-icon color="blue darken-4" class="cursor-move draggable-drag-icon">mdi-drag</v-icon>
						</td> -->
						<td class="p-2 border-top-light-grey text-center">
							{{ index + 1 }}
						</td>
						<td class="p-2 border-top-light-grey">
							{{ row.barcode }}
						</td>
						<td class="p-2 border-top-light-grey">
							{{ row.added_at }}
						</td>
						<td class="p-2 border-top-light-grey">
							{{ row.user_name }}
						</td>
						<td class="p-2 border-top-light-grey">
							<v-chip
								v-if="row.receive_status"
								:color="getStatusColor(row.receive_status_value)"
								class="white--text mr-2"
								label
							>
								{{ row.receive_status_value }}
							</v-chip>
							<v-chip
								v-if="row.force_close_status_value"
								:color="'red lighten-1'"
								class="white--text"
								label
							>
								{{ row.force_close_status_value }}
							</v-chip>
						</td>
						<td class="p-2 border-top-light-grey">
							<div v-if="row.document_url">
								<v-tooltip top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<span v-on="on" v-bind="attrs">
											<v-btn
												fab
												icon
												small
												:disabled="pageLoading"
												:loading="row.downloading"
												@click.stop.prevent="downloadAttachement(row.document_url)"
											>
												<v-icon color="" class="cursor-pointer">mdi-download-circle</v-icon>
											</v-btn>
										</span>
									</template>
									<span>Download Attachement</span>
								</v-tooltip>
								<span>{{ row.document_name }}</span>
							</div>
							<em v-else class="text-muted">no attachement</em>
						</td>
						<td class="p-2 border-top-light-grey text-center green--text">
							<v-btn
								fab
								icon
								small
								:disabled="pageLoading"
								:loading="row.downloading"
								@click.stop.prevent="downloadPDF(row.uuid, index)"
							>
								<v-icon color="red" class="cursor-pointer">mdi-file-pdf</v-icon>
							</v-btn>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="5">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no {{ getTitle() }} at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog="detailDialog" :dialog-width="900" dense v-if="detailDialog">
			<template v-slot:title>
				<div class="d-flex align-items-center w-100">
					<div>GRN Detail</div>
					<v-spacer></v-spacer>
					<v-icon @click="detailDialog = false">mdi-close</v-icon>
				</div>
			</template>
			<template v-slot:body>
				<Loading v-if="detailLoading"></Loading>
				<v-col v-else md="12" class="px-5">
					<v-row>
						<v-col md="6">
							<table class="w-100" border="0" cellspacing="0" cellpadding="4">
								<tr>
									<th class="pa-2 font-weight-500" width="100">GRN No.</th>
									<td class="pa-2">
										<ShowValue :object="grnDetail" object-key="barcode" label="barcode"></ShowValue>
									</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="100">Date</th>
									<td class="pa-2">{{ formatDate(grnDetail.added_at) }}</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="100">Total items</th>
									<td class="pa-2">
										<v-chip label class="px-2" color="blue darken-4 white--text">
											<template v-if="grnDetail.total_items">
												{{ grnDetail.total_items }}
											</template>
											<template v-else> 0 </template>
										</v-chip>
									</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="100">Remark</th>
									<td class="pa-2">
										<ShowTextAreaValue
											:object="grnDetail"
											object-key="remark"
											label="remark"
										></ShowTextAreaValue>
									</td>
								</tr>
							</table>
						</v-col>
						<v-col md="6">
							<table class="w-100" border="0" cellspacing="0" cellpadding="4">
								<tr>
									<th class="pa-2 font-weight-500" width="150">Barcode</th>
									<td class="pa-2">
										<ShowValue
											:object="grnDetail"
											object-key="customer_barcode"
											label="customer barcode"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="150">Name</th>
									<td class="pa-2">
										<ShowValue
											:object="grnDetail"
											object-key="customer_name"
											label="customer name"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="150">Company Name</th>
									<td class="pa-2">
										<ShowValue
											:object="grnDetail"
											object-key="customer_company_name"
											label="company name"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="150">Email</th>
									<td class="pa-2">
										<ShowValue :object="grnDetail" object-key="customer_email" label="email"></ShowValue>
									</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="150">Phone</th>
									<td class="pa-2">
										<ShowValue :object="grnDetail" object-key="customer_phone" label="phone"></ShowValue>
									</td>
								</tr>
								<tr>
									<th class="pa-2 font-weight-500" width="150">Address</th>
									<td class="pa-2">
										<ShowValue :object="grnDetail" object-key="customer_address" label="address"></ShowValue>
									</td>
								</tr>
							</table>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="12" class="mb-5">
							<table class="w-100 detail-table table-head-sticky">
								<thead class="">
									<th class="pa-2 blue lighten-4 text-center" width="40">#</th>
									<th class="pa-2 blue lighten-4">Product/Service</th>
									<th class="pa-2 blue lighten-4 text-center" with="100">Qty</th>
									<th class="pa-2 blue lighten-4 text-center" width="150">Receive Qty</th>
								</thead>
								<tbody>
									<tr v-for="(row, index) in grnDetail.line_items" :key="index">
										<td class="pa-2 border-top-light-grey text-center">{{ index + 1 }}</td>
										<td class="pa-2 border-top-light-grey">{{ row.barcode }} - {{ row.name }}</td>
										<td class="pa-2 border-top-light-grey text-center">{{ row.required_qty }}</td>
										<td class="pa-2 border-top-light-grey text-center">{{ row.received_qty }}</td>
									</tr>
								</tbody>
							</table>
						</v-col>
					</v-row>
				</v-col>
			</template>
			<template v-slot:action> </template>
		</Dialog>
	</div>
</template>
<script>
// import Draggable from "vuedraggable";
import Loading from "@/view/components/Loading";
import { GetLineItem, DownloadPDF } from "@/core/lib/purchase-order.lib";
import Dialog from "@/view/components/Dialog";
import ShowValue from "@/view/components/ShowValue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import { GetGRNDetail } from "@/core/lib/purchase-order.lib";
import { map } from "lodash";
export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "all",
		},
		id: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			contentLoading: false,
			grnDownloading: false,
			line_items: [],
			pageLoading: false,
			detailLoading: false,
			detailDialog: false,
			grnDetail: {},
		};
	},
	methods: {
		getTitle() {
			return "GRN Number";
		},
		async getGRNListingItem() {
			const param = {
				purchase_order: this.id,
				option: "grn_listing",
			};
			this.contentLoading = true;
			const data = await GetLineItem(param);
			this.line_items = map(data.grn_history, (row) => {
				return { ...row, downloading: false };
			});
			this.contentLoading = false;
		},
		updateValue(value) {
			console.log("UpdateValue", value);
		},
		async downloadAllGRN() {
			this.grnDownloading = true;
			if (this.line_items.length) {
				await DownloadPDF({ uuid: this.typeUuid, url: "generate-pdf/purchase-order/grn-listing/" });
			}
			this.grnDownloading = false;
		},
		async downloadPDF(uuid, index) {
			this.pageLoading = true;
			this.line_items[index].downloading = true;
			await DownloadPDF({ uuid: uuid, url: "generate-pdf/purchase-order/grn/" });
			this.line_items[index].downloading = false;
			this.pageLoading = false;
		},
		downloadAttachement(document_url) {
			window.open(document_url, "_blank");
		},
		async getDetail(uuid) {
			this.detailDialog = true;
			this.detailLoading = true;
			const data = await GetGRNDetail(uuid);
			this.grnDetail = data[0];
			this.detailLoading = false;
		},
	},
	components: {
		// Draggable,
		Dialog,
		ShowValue,
		ShowTextAreaValue,
		Loading,
	},
	mounted() {
		if (this.id) {
			this.getGRNListingItem();
		}
	},
};
</script>
<style>
.font-weight-500 {
	font-weight: 500;
}
</style>
