<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md5 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				{{ getTitle() }}
			</v-flex>
			<v-flex
				md7
				class="font-level-3-bold my-auto d-flex justify-content-end align-items-center"
				v-if="false"
			>
				<span class="mr-1">AMOUNT </span
				><span style="font-size: 24px" class="blue--text text--darken-4">$200</span>
			</v-flex>
		</v-layout>
		<template v-if="contentLoading">
			<Loading />
		</template>
		<div v-else class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 blue lighten-4 text-center" width="30"></th>
						<th class="p-2 blue lighten-4 text-center" width="50">#</th>
						<th class="p-2 blue lighten-4" width="250">Item</th>
						<th class="p-2 blue lighten-4" min-width="300">Description</th>
						<th class="p-2 blue lighten-4" width="100">UOM</th>
						<th class="p-2 blue lighten-4 text-center" width="120">Qty</th>
						<th class="p-2 blue lighten-4 text-right" width="160">Rate</th>
						<th class="p-2 blue lighten-4 text-right pr-5" width="160">Amount</th>
					</tr>
				</thead>
				<!-- <tbody v-if="line_items.length"> -->
				<template v-if="line_items.length">
					<Draggable
						tag="tbody"
						v-model="line_items"
						class="draggable-group"
						handle=".draggable-drag-icon"
						v-on:change="updateLineItem()"
					>
						<tr v-for="(row, index) in line_items" :key="index">
							<td class="p-2 border-top-light-grey text-center">
								<v-icon color="blue darken-4 " class="cursor-move draggable-drag-icon">mdi-drag</v-icon>
							</td>
							<td class="p-2 border-top-light-grey text-center">
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowValue :object="row" object-key="product_name" label="product name"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowValue :object="row" object-key="description" label="description"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowValue :object="row" object-key="uom" label="uom"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey text-center">
								{{ row.quantity ? row.quantity : 0 }}
							</td>
							<td class="p-2 border-top-light-grey text-right">
								<template v-if="false && row.is_price_changed">
									<v-icon color="red" size="12">mdi-circle</v-icon>
									({{ row.dprice ? `$${row.dprice}` : 0 }})
								</template>
								{{ formatMoney(row.rate ? row.rate : 0) }}
							</td>
							<td class="p-2 border-top-light-grey text-right pr-5">
								{{ formatMoney(row.total ? row.total : 0) }}
							</td>
							<td class="p-2 border-top-light-grey" v-if="false">
								<!-- {{ row.recieved }} -->
								<InputEdit v-model="row.recieved" v-on:update="updateValue($event)"> </InputEdit>
								<div style="width: 60px" class="mx-auto" v-if="false">
									<TextInput v-model="row.recieved" hide-details class="mt-0"></TextInput>
									<div class="w-100 px-3 py-1">{{ row.recieved }}</div>
								</div>
							</td>
						</tr>
					</Draggable>
					<tfoot style="font-size: 14px">
						<tr>
							<td colspan="7" class="p-2 text-right"><b>Sub Total</b></td>
							<td class="p-2 text-right pr-5">
								<b>{{ formatMoney(line_items_detail.sub_total) }}</b>
							</td>
						</tr>
						<tr>
							<td colspan="7" class="p-2 text-right">
								<b
									>Discount ({{ line_items_detail.discount_value_type == 1 ? "$" : ""
									}}{{ line_items_detail.discount_value
									}}{{ line_items_detail.discount_value_type == 2 ? "%" : "" }})</b
								>
							</td>
							<td class="p-2 text-right pr-5">
								<b>{{ formatMoney(line_items_detail.discount_amount) }}</b>
							</td>
						</tr>
						<tr v-if="line_items_detail.tax_applied">
							<td colspan="7" class="p-2 text-right">
								<b>Tax {{ line_items_detail.tax_value }}%</b>
							</td>
							<td class="p-2 text-right pr-5">
								<b>{{ formatMoney(line_items_detail.tax_amount) }}</b>
							</td>
						</tr>
						<tr>
							<td colspan="7" class="p-2 text-right"><b>Adjustment</b></td>
							<td class="p-2 text-right pr-5">
								<b>{{ formatMoney(line_items_detail.adjustment) }}</b>
							</td>
						</tr>
						<tr>
							<td colspan="7" class="p-2 text-right green--text"><b>Grand Total</b></td>
							<td class="p-2 text-right green--text pr-5">
								<b>{{ formatMoney(line_items_detail.total) }}</b>
							</td>
						</tr>
					</tfoot>
				</template>
				<!-- </tbody> -->
				<tfoot v-else>
					<tr>
						<td colspan="6">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no {{ getTitle() }} at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
import InputEdit from "@/view/components/InputEdit";
import ShowValue from "@/view/components/ShowValue";
import { GetLineItem } from "@/core/lib/purchase-order.lib";
import Loading from "@/view/components/Loading";
import Draggable from "vuedraggable";
export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		itemType: {
			type: String,
			default: null,
		},
		uuid: {
			type: String,
			default: null,
		},
		id: {
			type: Number,
			default: 0,
		},
		status: {
			type: String,
			default: "all",
		},
	},
	data() {
		return {
			contentLoading: false,
			line_items: [],
			line_items_detail: {
				total: 1111,
				sub_total: 1111,
				tax_type: 1,
				discount_type: 2,
				discount_value_type: 2,
				total_items: null,
				discount_value: 15,
				discount_amount: 10,
				tax_value: 7,
				taxable_amount: 0,
				tax_amount: 0,
				adjustment: 100,
				tax_applied: 0,
				gst: 0,
			},
			pageLoading: true,
		};
	},
	methods: {
		getTitle() {
			return "Line Items";
		},
		async getPoLineItems(id) {
			const param = {
				purchase_order: id,
				option: "line_items",
			};
			this.contentLoading = true;
			const data = await GetLineItem(param);
			this.contentLoading = false;
			this.line_items_detail = data.detail;
			this.line_items = data.line_items;
		},
		updateValue() {
			//console.log("UpdateValue", value);
		},
		updateLineItem() {
			//console.log(this.line_items);
		},
	},
	components: {
		TextInput,
		InputEdit,
		Draggable,
		ShowValue,
		Loading,
	},
	mounted() {
		if (this.id) {
			this.getPoLineItems(this.id);
		}
	},
};
</script>
